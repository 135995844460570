import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBJQ-GYrCbkiq7VG4Y1M3YJ9vJUGTu9VX8",
  authDomain: "einfach-340407.firebaseapp.com",
  databaseURL: "https://einfach-340407-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "einfach-340407",
  storageBucket: "einfach-340407.appspot.com",
  messagingSenderId: "149466847553",
  appId: "1:149466847553:web:bcaf2bc6f1e71f49c469c0",
  measurementId: "G-2V0GSCVXWM"
};
// Initialize Firebase

// eslint-disable-next-line no-unused-vars
let app = null
if (!firebase.apps.length) {
  // eslint-disable-next-line no-unused-vars
  app = firebase.initializeApp(firebaseConfig)
}

export const DB = firebase.database();

export default firebase